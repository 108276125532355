








import { Component, Vue } from 'vue-property-decorator'; // import { userModule } from '@/store/modules/user/user.module';

@Component({})
export default class NotFoundPage extends Vue {}
